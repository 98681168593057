import React from 'react';
import { Container } from './style';

import feedbackImg from '../../../../../assets/images/feedback.svg';

export const FeedbackButton = () => (
  <Container>
    <div>
      <img src={feedbackImg} alt="Feedback" />
    </div>
    <p>Avalie!</p>
  </Container>
);
