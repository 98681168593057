import React, { useEffect, useState } from 'react';
import shortid from 'shortid';

import { Footer } from '../../../components/Footer';
import LoaderSpinner from '../../../components/LoaderSpinner';

import api from '../../../services/api';

import { LoadingContainer } from '../Customers/styles';
import { LogoContainer, TitlePage } from '../Customers/styles';
import './styles.css';

interface ClientData {
  name: string;
  logo: string;
  menuUrl: string;
}

export const DemoPage = () => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<ClientData[]>([]);

  useEffect(() => {
    api
      .get(`api/boot/clients`)
      .then((response) => {
        const clients = response.data.data as ClientData[];
        clients.sort((a, b) => a.name.localeCompare(b.name));
        setData(clients);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Fail loding menu', error);
      });
  }, []);

  return (
    <div id="page-internal-page">
      <div className="content">
        <main className="main-internal-page">
          <LogoContainer
            onClick={() => {
              window.open('https://qrcodepreferido.com/', '_blank');
            }}
          >
            <img
              className="logo-specs"
              src={require('../../../assets/images/logo-bg-transparent-text-white.png')}
              alt="QRcode Preferido"
            />
          </LogoContainer>

          <TitlePage>
            <h1>Nossos Clientes</h1>
          </TitlePage>

          {isLoading ? (
            <LoadingContainer>
              <LoaderSpinner isSmall />
            </LoadingContainer>
          ) : (
            <ul
              style={{ marginTop: '20px' }}
              className="section-title ul-internal-page"
            >
              {data.map((menuItem) => {
                return (
                  <div key={shortid.generate()}>
                    <li className="li-internal-page">
                      <a
                        href={menuItem.menuUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {menuItem.name}
                      </a>
                    </li>
                  </div>
                );
              })}
            </ul>
          )}
        </main>

        <Footer
          instagram="https://instagram.com/qrcodepreferido"
          facebook="https://facebook.com/QRcode-Preferido-108736257559674/"
          whatsapp="https://api.whatsapp.com/send?phone=351910919855"
          phone="351910919855"
        />
      </div>
    </div>
  );
};
