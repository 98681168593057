import { useFetch } from "../../../../hooks/useFetch";
import { BootClient } from "../../../../models/DataResponse";

const RODEADOURO = 'rodeadouro';

export const useRodeadouro = () => {
  const { data, isLoading } = useFetch<BootClient>({
    queryKey: [RODEADOURO],
    staleTime: 1000 * 60 * 60,
    url: 'api/boot/rodeadouro',
  });

  return {
    data,
    configuration: data?.configuration,
    company: data?.company,
    isLoading,
  };
};